import logoLight from "@/images/logo3.png";
import logoDark from "@/images/logo2.png";
import LogoSmall from "@/images/logo-small.png";
import PhoneIcon from "@/images/phone-icon.png";
import MailIcon from "@/images/mail-icon.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
  small: LogoSmall,
};

export const Icons = {
  phone: PhoneIcon,
  mail: MailIcon,
};

export const NavLinks = [
  {
    name: "Эхлэл",
    url: "/",
  },
  {
    name: "Бидний тухай",
    url: "/about",
  },
  {
    name: "Үйлчилгээ",
    url: "/service",
    subItems: [
      {
        name: "ПРОГРАММ",
        url: "/service",
      },
      {
        name: "ТЕХНИК ЗАСВАР",
        url: "/service-details",
      },
      {
        name: "ЛЕЦЕНЗ СУНГАХ",
        url: "/service-details",
      },
    ],
  },
  {
    name: "Бүтээгдэхүүн",
    url: "/portfolio",
    subItems: [
      {
        name: "LEADER санхүүийн тооцоолох програм",
        url: "/portfolio-details",
      },
      {
        name: "Acolous нягтлан бодох бүртгэлийн программ",
        url: "/portfolio-details",
      },
      {
        name: "Payroll санхүүийн тооцоолох програм",
        url: "/portfolio-details",
      },
      {
        name: "Fiscus төрийн сангийн програм",
        url: "/portfolio-details",
      },
    ],
  },
  {
    name: "Холбоо барих",
    url: "/contact",
  },
];

import sliderOne01 from "@/images/slider/image.jpg";
import sliderOne02 from "@/images/slider/1_2.jpg";

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "Эрхэм зорилго",
    title: "Технологийн дэвшилийг \n бид хүргэнэ.",
    button: {
      label: "дэлгэрэнгүй ...",
      url: "/about",
    },
  },
  {
    image: sliderOne01,
    subTitle: "Алсын хараа",
    title: "2024 онд олон улсын зах зээлд бүтээгдэхүүн, үйлчилгээ нэвтрүүлэх",
    button: {
      label: "дэлгэрэнгүй ...",
      url: "/about",
    },
  },
];

import sliderTwo01 from "@/images/slider/2_1.jpg";
import sliderTwo02 from "@/images/slider/2_2.jpg";
import sliderTwo03 from "@/images/slider/2_3.jpg";

export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: "",
    title: "PAYROLL",
    button: {
      label: "ДЭЛГЭРЭНГҮЙ",
      url: "/about",
    },
  },
  {
    image: sliderTwo02,
    subTitle: "",
    title: "LEADER",
    button: {
      label: "ДЭЛГЭРЭНГҮЙ",
      url: "/about",
    },
  },
  {
    image: sliderTwo03,
    subTitle: "",
    title: "ACOLOUS",
    button: {
      label: "ДЭЛГЭРЭНГҮЙ",
      url: "/about",
    },
  },
];

import sliderThree01 from "@/images/slider/3_1.jpg";
import sliderThree02 from "@/images/slider/3_2.jpg";

export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "austin",
    infos: [
      {
        name: "22 Texas West Hills",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "boston",
    infos: [
      {
        name: "5 Federal Street Boston",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "new york",
    infos: [
      {
        name: "8th Broklyn New York",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "baltimore",
    infos: [
      {
        name: "3 Lombabr 50 baltimore",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "БИДЭНТЭЙ ХОЛБОО БАРИХ",
  title: "",
  description:
    "Санал хүсэлт илгээх ",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/1.jpg";
import portfolio02 from "@/images/portfolio/2.jpg";
import portfolio03 from "@/images/portfolio/3.jpg";
import portfolio04 from "@/images/portfolio/4.jpg";

export const PortfolioData = [
  {
    title: "LEADER санхүүийн тооцоолох програм",
    categories: [],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "Acolous нягтлан бодох бүртгэлийн программ",
    categories: [],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "Payroll санхүүийн тооцоолох програм",
    categories: [],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "Fiscus төрийн сангийн програм",
    categories: [],
    image: portfolio04,
    url: "/portfolio-details",
  }
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }],
  title: "leader санхүүгийн тооцоолох програм",
  text:
    " Leader 3.0 хувилбар нь аж ахуйн нэгж байгууллагад гарсан ажил гүйлгээ бүрийг цаг тухайд нь бүртгэн анхан шатны баримтуудыг бүрдүүлэн авах, өдөр тутмын санхүүгийн үйл ажиллагаандаа хяналт тавих, тайлан мэдээг хүссэн хугацааны хооронд гаргах удирдлагыг мэдээллээр түргэн шуурхай хангахаас гадна Payroll 2.0 (Цалингийн програм), Leader-Pos (Дэлгүүрийн Пос програм) програмуудтай холбогдон ажиллах боломжтой систем юм.Програм хэрэглэснээр гарах  үр дүн: Payroll 2.0 (Цалингийн програм), Leader-Pos (Дэлгүүрийн Пос програм) програмуудтай холбогдон ажиллана. Нийгмийн даатгалын тайлан хүн тус бүрээр болон НДШимтгэл төлөлтийн тайлан хэвлэгдэн гарна. Хөдөлмөр хөнгөвчлөх үүднээс үндсэн хөрөнгийн элэгдэл, цалингийн журнал гэх мэт байнга давтагддаг журналын бичилтүүдийг автоматаар бичдэг. Хэд хэдэн байгууллагын бүртгэлийг нэгэн зэрэг хөтлөх боломж бий болно. "
};

export const ServicePostData = [
  {
    title: "ПРОГРАММ",
    text:
      "Програмын засвар үйлчилгээ нь өдөр тутмын үйлчилгээний ихэнх хувийг эзэлдэг ба олон жилийн туршлагад тулгуурласан өндөр түвшинд бэлтгэгдсэн мэргэжлийн хүмүүс үйлчилгээ үзүүлж буй бөгөөд техник, технологийн хурдацтай хөгжлийн энэ үед бид хэрэглэгч танд алсын зайнаас програмын засвар үйлчилгээг үзүүлэх боломжийг бүрдүүлэн ажиллаж байна.",
    iconName: "mei-computer-graphic",
    url: "/service-details",
  },
  {
    title: "ТЕХНИК ЗАСВАР",
    text:
      "Энэ төрлийн үйлчилгээ нь хэрэглэгчдийн ажил үүргээ гүйцэтгэх таатай нөхцөлийг бүрдүүлэх суурь болж өгдөг. Үйлчлүүлэгч байгууллагуудын дотоод сүлжээг угсрах, техник тоног төхөөрөмжийн засвар, оношилгоо хийх, вирусын програм суулгах зэрэг өдөр тутмын үйл ажиллагаанд зайлшгүй шаардлагатай техникийн үйлчилгээг байнга тасралтгүй, тогтмол үзүүлж байна",
    iconName: "mei-development",
    url: "/service-details",
  },
  {
    title: "ЛЕЦЕНЗ СУНГАХ",
    text:
      "Энэ төрлийн үйлчилгээ нь хэрэглэгчдийн ажил үүргээ гүйцэтгэх таатай нөхцөлийг бүрдүүлэх суурь болж өгдөг. Үйлчлүүлэгч байгууллагуудын дотоод сүлжээг угсрах, техник тоног төхөөрөмжийн засвар, оношилгоо хийх, вирусын програм суулгах зэрэг өдөр тутмын үйл ажиллагаанд зайлшгүй шаардлагатай техникийн үйлчилгээг байнга тасралтгүй, тогтмол үзүүлж байна.",
    iconName: "mei-app-development",
    url: "/service-details",
  },
];

export const ServiceHomeTwoData = {
  subTitle: "welcome to smart meipaly web agency",
  title: "We design digital products that \n help grow businesses.",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Бид танд дараах үйлчилгээг санал болгож байна.",
    subTitle: "Манай үйлчилгээнүүд",
    text:
      "Програм хангамжийн засвар үйлчилгээ нь манай байгууллагын үйлчилгээний ихэнх хувийг эзэлдэг бөгөөд энэ үйлчилгээг өндөр түвшинд бэлтгэгдсэн мэргэжлийн хүмүүс явуулж байна. Хэрэглэгчдийн санал хүсэлт, технологийн дэвшилтэй уялдан алсын зайнаас засвар үйлчилгээ үзүүлж эхлээд байна.",
  },
  posts: [
    {
      title: "Программ",
      iconName: "mei-computer-graphic",
      url: "/service-details",
    },
    {
      title: "Техник засвар",
      iconName: "mei-development",
      url: "/service-details",
    },
    {
      title: "ЛЕЦЕНЗ сунгах",
      iconName: "mei-app-development",
      url: "/service-details",
    },
  ],
};

import serviceOne01 from "@/images/home_1/6.jpg";
import serviceOne02 from "@/images/home_1/7.jpg";
import serviceOne03 from "@/images/home_1/8.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Let’s create something",
    subTitle: "what we do",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      title: "web development",
      image: serviceOne01,
      url: "/service-details",
    },
    {
      title: "digital marketing",
      image: serviceOne02,
      url: "/service-details",
    },
    {
      title: "product branding",
      image: serviceOne03,
      url: "/service-details",
    },
  ],
};

export const FunfactData = [
  {
    title: "Монгол улсад нийт",
    countNumber: 705,
  },
  {
    title: "Улаанбаатар хот",
    countNumber: 480,
  },
  {
    title: "Аймагууд",
    countNumber: 626,
  },
  {
    title: "Сумууд",
    countNumber: 774,
  },
];

import trustClient01 from "@/images/home_1/4.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 8900 clients",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "Манай хамтран ажилдаг багууллагууд",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
  ],
};

import aboutOne01 from "@/images/about/1.jpg";
import aboutOne02 from "@/images/about/2.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "build better website alot quicker with meipaly agency",
    subTitle: "get to know us",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Company Started",
    number: 1990,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "expert people",
    subTitle: "meet the team",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  posts: [
    {
      image: team01,
      name: "Herman Fisher",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Charlie Kennedy",
      designation: "Brand Designer",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Helena Adkins",
      designation: "Seinor Designer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Virginia Farmer",
      designation: "Marketing Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Philip Hansen",
      designation: "Co Founder",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe us",
    subTitle: "don’t miss out our latest updates",
  },
};

import testimonial01 from "@/images/home_1/t1.jpg";
import testimonial02 from "@/images/home_1/t2.jpg";
import testimonial03 from "@/images/home_1/t3.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "our testimonials",
    title: "happy customers",
  },
  posts: [
    {
      name: "Cecilia Colon",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Chase Hanson",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Mittie Flores",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
  ],
};

import featureTab01 from "@/images/home_1/c1.jpg";
import featureTab02 from "@/images/home_1/c2.jpg";
import featureTab03 from "@/images/home_1/c3.jpg";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  posts: [
    {
      title: "Latest technology",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab01,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "Awesome Support",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab02,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      title: "1 Click demo install",
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab03,
      lists: [
        {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Great things in business are never done by one person.",
  specialText: "They’re done by a team of people.",
  text:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "work showcase",
    subTitle: "our portfolio",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
};

import video02 from "@/images/home_1/3.jpg";

export const VideoTwoData = {
  sectionContent: {
    title: "Digital Experience",
    subTitle: "how do we works",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
  },
};

import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "Бидний товч танилцуулга",
    text:
      "Санхүүгийн тооцоолох компани нь анх 1998 онд санхүүгийн програм хангамж боловсруулах, техник хангамжийн засвар үйлчилгээ хийх зорилготойгоор байгуулагдсан. Тэр цагаас хойш өнөөг хүртэл үйл ажиллагаагаа тасралтгүй явуулж нийслэл болон орон нутгийн 2000 гаруй байгууллагад Санхүү, Нягтлан бодох бүртгэлийн програм хангамжийг суурилуулан ажил үйлчилгээгээ улам бүр өргөжүүлэн ажиллаж байна. Мөн үйлчлүүлэгчдээ иж бүрэн үйлчилгээг үзүүлэх зорилгоор компьютер, тоног төхөөрөмж, антивирусны програмын худалдаа эрхлэн явуулж байна.",
  },
  button: {
    label: "Дэлгэрэнгүй ...",
    url: "/about",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      " We are committed to providing our customers with exceptional service while offering our employees the best training. ",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
